
<style lang="scss">
@import "@/assets/sass/pages/login/login.scss";
</style>
<template>
  <div>
    <div class="transparent-layer ext-layer" style="opacity: 57%;" ></div>
    <div id="all-panel">
      <div class="comp-detail">
        <div class="comp-container">
          <!-- <img src="/media/logos/nor-logo.jpeg" alt="company_logo" /> -->
          <h4>Norvic International Hospital</h4>
          <p>Thapthali, Kathmandu</p>
          <p>+977-1-5970032</p>
        </div>
        <div class="website-footer">
          <a href="">www.websitelink.com.np</a>
        </div>
      </div>
      <div class="login-panel" style="opacity: 90%;">
        <div class="login-content">
          <div class="login-title">
            <h3>Welcome, Please Login</h3>
            <p>You can sign in to access with your existing account</p>
          </div>
          
          <div class="login-form">
            <form @submit.stop.prevent="onSubmit">
             
              <div class="form-group">
                <label for="">Email Address</label>
                <div class="group">
                  <div class="icon">
                    <img
                      src="media/login/icon/large-mail.svg"
                      alt="icon-mail"
                    />
                  </div>
                  <input
                    v-model="email"
                    type="email"
                    placeholder="Email Address"
                    class="form-control "
               :class="errors ? (errors['email'] ? 'is-invalid' : '') : ''"
                  />
                    <b-form-invalid-feedback id="input-2-live-feedback">
            {{ errors ? (errors["email"] ? errors["email"][0] : "") : "" }}
          </b-form-invalid-feedback>
                
                </div>
              </div>
              <div class="form-group">
                <label for="">Password</label>

                <div class="group">
                  <div class="icon">
                    <img
                      src="media/login/icon/large-passkey.svg"
                      alt="icon-passkey"
                    />
                  </div>
                  <input
                    v-model="password"
                          :class="errors ? (errors['password'] ? 'is-invalid' : '') : ''"
                    type="password"
                    placeholder="Password"
                    class="form-control"
                  />
  <b-form-invalid-feedback id="input-2-live-feedback">
            {{ errors ? (errors["password"] ? errors["password"][0] : "") : "" }}
          </b-form-invalid-feedback>
              
                 
                </div>
              </div>

              <div class="form-action">
                      <div
          class="alert "
          :class="errors ? (errors['type'] ? errors['type'] : '') : ''"
          role="alert"
          v-if="errors ? (errors['msg'] ? true : false) : false"
        >
          {{ errors["msg"] }}
        </div>
                <a href="">Request Password!</a>
                <button
                  class="btn btn-success float-right"
                  ref="kt_login_signin_submit"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="login-content-footer">
          <ul>
            <li>
              <a href="">Terms</a>
            </li>
            <li>
              <a href="">Plans</a>
            </li>
            <li>
              <a href="">Contact Us</a>
            </li>
          </ul>
          <img src="media/logos/mavorion_logo.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOGIN, LOGOUT } from "@/core/services/store/actions.type";

import { mapState } from "vuex";
export default {
  data() {
    return {
      // Remove this dummy login info
   
      email: "",
      password: "",
    };
  },

  computed: {

    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
  methods: {
    onSubmit() {
      const email = this.email;
      const password = this.password;
      // clear existing errors
      this.$store.dispatch(LOGOUT);
      // s spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.disabled = true;

      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(LOGIN, { email, password })
        // go to which page after successfully login
        .then(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$router.push({ name: "dashboard" });
        })
        .catch(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        });
    },
  },
};
</script>